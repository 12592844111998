import { createContext, useContext } from 'react';

import type { PropsWithChildren } from 'react';
import type { LeapFeature, LeapFeatures } from '-/leap-models';

interface FeaturesProvider {
  features: LeapFeature[];
}

interface FeaturesProviderContext {
  features: LeapFeature[];
  feature: (feature: string) => LeapFeature | undefined;
  isFeatureEnabled: (feature: string) => boolean;
  isFeatureLimitReached: (feature: string, usage: number) => boolean;
  getRemainingFeatureLimit: (feature: string, usage: number) => number;
}

const FeaturesContext = createContext({
  features: [] as LeapFeature[],
  feature: (_feature: string) => undefined as LeapFeature | undefined,
  isFeatureEnabled: (_feature: string) => false as boolean,
  isFeatureLimitReached: (_feature: string, _usage: number) => false as boolean,
  getRemainingFeatureLimit: (_feature: string, _usage: number) => 0 as number,
});

export const useFeaturesContext = () => useContext(FeaturesContext);

export const FeaturesProvider = ({
  children,
  features,
}: FeaturesProvider & PropsWithChildren) => {
  const isFeatureEnabled = (featureKey: LeapFeatures) => {
    return feature(featureKey)?.enabled || false;
  };

  const feature = (featureKey: LeapFeatures) => {
    return features?.find((feature) => feature.key === featureKey);
  };

  const isFeatureLimitReached = (featureKey: LeapFeatures, usage: number) => {
    return getRemainingFeatureLimit(featureKey, usage) <= 0;
  };

  const getRemainingFeatureLimit = (
    featureKey: LeapFeatures,
    usage: number
  ) => {
    const remaining = Number(feature(featureKey)?.limit) - usage;
    return feature(featureKey)?.enabled ? remaining : 0;
  };

  const contextValue = {
    features,
    isFeatureEnabled,
    isFeatureLimitReached,
    feature,
    getRemainingFeatureLimit,
  } as FeaturesProviderContext;

  return (
    <FeaturesContext.Provider value={contextValue}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
